<template>
  <div id="changeTelphont">
    <div class="steps">
      <el-steps align-center :active="active" >
        <el-step title="验证身份信息"></el-step>
        <el-step title="绑定新手机"></el-step>
        <el-step title="换绑成功"></el-step>
      </el-steps>
    </div>
    <div class="step1" v-if="active === 0">
      <el-form :model="verifyForm" :rules="verifyRules" ref="verifyForm" label-width="130px" class="demo-ruleForm">
        <el-form-item label="已绑定手机号："  prop="telphone">
          <div class="personal_info">
            {{this.verifyForm.telphone}}
          </div>
        </el-form-item>
        <el-form-item label="短信验证码：" prop="sms" class="v_code">
          <el-input placeholder="请输入验证码" v-model="verifyForm.sms" class="v_code_input"></el-input>
          <div v-if="smsCode" class="v_code_btn" @click="sentVCode(verifyForm.telphone)">获取</div>
          <div v-else class="v_code_btn smsFalse">{{ smsTime }}s</div>
        </el-form-item>
      </el-form>
      <div class="step1Btn" @click="step1Next('verifyForm')">
        <div>下一步</div>
      </div>
    </div>
    <div class="step2" v-if="active === 1">
      <el-form :model="newForm" :rules="newRules" ref="newForm" label-width="130px" class="demo-ruleForm">
        <el-form-item label="换绑手机号码：" prop="telphone">
          <el-input placeholder="请输入修改后的手机号" v-model="newForm.telphone"></el-input>
        </el-form-item>
        <el-form-item label="短信验证码：" prop="sms" class="v_code">
          <el-input placeholder="请输入验证码" v-model="newForm.sms" class="v_code_input"></el-input>
          <div v-if="smsCode" class="v_code_btn" @click="sentVCode(newForm.telphone)">获取</div>
          <div v-else class="v_code_btn smsFalse">{{ smsTime }}s</div>
        </el-form-item>
      </el-form>
      <div class="step2Btn" @click="step2Next('newForm')">
        <div>确认换绑</div>
      </div>
    </div>
    <div class="step3" v-if="active === 2">
      <div class="step3Img">
        <img src="@/assets/image/PersonalCenter/PersonalInfo/changePhoneSuccess.png" alt="">
      </div>
      <router-link tag="div" :to="{name:'BasicInfo'}" class="step3Btn" >
        <div>返回</div>
      </router-link>
    </div>
  </div>
</template>
<script>

import {apiSmsSend,apiSmsCheck,apiChangePhone} from "@/request/API";

export default {
  name: 'changeTelphont',
  props: {
  },
  components: {

  },
  data () {
    return {
      active: 0,
      verifyForm:{
        telphone:this.$store.state.userData.telphone,
        sms:''
      },
      verifyRules: {
        sms: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      },
      newForm:{
        telphone:'',
        sms:''
      },
      newRules: {
        telphone:[
          { required: true, message: '请输入修改后的手机号', trigger: 'blur' },
          { pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }
        ],
        sms: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      },
      //获取验证码
      smsCode:true,
      smsTime:60
    }
  },
  methods: {
    /**
     * 验证身份信息 提交按钮
     * @param formName
     */
    step1Next(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid){
          apiSmsCheck({
            phone:this.verifyForm.telphone,
            smsCode:this.verifyForm.sms
          }).then(res=>{
            this.smsCode = true
            if (this.active++ >= 2) this.active = 0;
          }).catch(err=>{
            this.$message({
              type:'error',
              center:true,
              message:err.msg
            })
          })
        }else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    /**
     * 绑定新手机 提交按钮
     * @param formName
     */
    step2Next(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid){
          apiChangePhone({
            phone:this.newForm.telphone,
            smsCode:this.newForm.sms
          }).then(res=>{
            this.$store.commit('set_telphone',this.newForm.telphone)
            this.smsCode = true
            if (this.active++ >= 2) this.active = 0;
          }).catch(err=>{
            this.$message({
              type:'error',
              center:true,
              message:err.msg
            })
          })
        }else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    sentVCode(phone){
      apiSmsSend({
        phone:phone,
        codeType:3
      }).then(res=>{
        this.$message({
          type:'success',
          center:true,
          message:"发送成功"
        })
        this.smsCode = false
      }).catch(err=>{
        this.$message({
          type:"error",
          center:true,
          message:err.msg
        })
      })
    },
    //获取验证码冷却
    countdownTime(){
      this.smsTime--
      if(this.smsTime === 0){
        this.smsCode = true
      }
    }
  },
  mounted () {

  },
  watch: {
    smsCode(val, oldVal){//普通的watch监听
      if(!val){
        this.smsTime = 60
        this.countdown = setInterval(this.countdownTime, 1000);
      }else{
        clearInterval(this.countdown)
      }
    },
  },
  computed: {
  },
  created() {
    if (localStorage.getItem('loginStatus') == "true") {
      this.verifyForm.telphone = JSON.parse(localStorage.getItem('userData')).telphone
    }
  }
}
</script>
<style lang='less' scoped>
  #changeTelphont{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0 0 0;
    /deep/.el-input{
      width: 250px;
    }
    /deep/.el-input__inner{
      width: 250px;
      height: 40px;
      background: #F9F9F9;
      border-radius: 4px;
      border: 1px solid #F0F0F0;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
    }
    /deep/.is-error .el-input__inner{
      border-color: #F56C6C;
    }
    /deep/.el-form-item__label{
      text-align: right;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      padding: 0;
    }
    /deep/.el-form-item:first-of-type{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    /deep/.el-form-item:first-of-type .el-form-item__content{
      margin: 0 !important;
    }
    /deep/.el-form-item .el-form-item__label{
      padding: 0 0 0 12px;
    }
    /deep/.is-required .el-form-item__label{
      padding:0;
    }
    .personal_info{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
      padding: 0 0 0 20px;
    }
    .v_code_input,.v_code_input /deep/.el-input__inner{
      width: 180px;
    }
    .v_code_btn{
      margin: 0 0 0 10px !important;
      width: 60px;
      height: 36px;
      line-height: 36px;
      background: #434ED6;
      border-radius: 10px;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #FFFFFF;
      cursor: pointer;
      text-align: center;
      display: inline-block;
    }
    .smsFalse{
      background: #f2f2f2;
      cursor: auto;
    }
    .steps{
      width: 450px;
      height: 102px;
      /deep/.el-step__line-inner{
        display: none !important;
      }
      /deep/.el-step__line{
        left: 0%;
        right: 0%;
        height: 2px;
      }
      /deep/.is-finish>.el-step__line{
        background-color:#434ED6;
        top: 10px;
        height: 4px;
      }
      /deep/.is-process>.el-step__line{
        background-color:#434ED6;
        top: 10px;
        height: 4px;
      }
      /deep/.is-wait>.el-step__line{
        display: block;
      }
      /deep/.is-process>.el-step__line{
        display: block;
      }
      /deep/.el-step__icon{
        width: 30px;
        height: 30px;
        line-height: 30px;
        border: none;
        font-size: 18px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #5B5B5B;
        background: #DDDDDD;
      }
      /deep/.is-finish>.el-step__icon{
        background: #434ED6;
        color: #FFFFFF;
      }
      /deep/.is-process>.el-step__icon{
        background: #434ED6;
        color: #FFFFFF;
      }
      /deep/.el-step__title{
        margin: 10px 0 0 0 ;
        font-size: 16px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #5B5B5B;
        line-height: 22px;
      }
    }
    .step1{
      .step1Btn{
        display: flex;
        justify-content: center;
        margin: 48px 0 0 0;
        div{
          width: 200px;
          height: 40px;
          background: #434ED6;
          border-radius: 20px;
          font-size: 18px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
    .step2{
      .step2Btn{
        display: flex;
        justify-content: center;
        margin: 48px 0 0 0;
        div{
          width: 200px;
          height: 40px;
          background: #434ED6;
          border-radius: 20px;
          font-size: 18px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
        }

      }
    }
    .step3{
      .step3Img{
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
          width: 197px;
          height: 155px;
          object-fit: cover;
        }
        .step3Img_tip{
          margin: 6px 0 0 0;
          font-size: 14px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #8F8F8F;
          line-height: 20px;
        }
      }
      .step3Btn{
        display: flex;
        justify-content: center;
        margin: 48px 0 0 0;
        div{
          width: 200px;
          height: 40px;
          background: #434ED6;
          border-radius: 20px;
          font-size: 18px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
        }

      }
    }
  }
</style>
